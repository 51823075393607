import React, { useState, useContext, useEffect, useMemo, useRef, useCallback } from "react";
import "./Lang.scss";
import Button from '@mui/material/Button';
import Text from 'components/form/Text';
import { ModelContext } from "providers/ModelProvider";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import {
  useParams,
} from "react-router-dom";
import { createPatch, applyPatch } from 'rfc6902';
import Alert from '@mui/material/Alert';

function Lang() {
  const lastLang=useRef({});
  const { id }=useParams();
  const { post, modele } = useContext(ModelContext);
  const lang=useMemo(()=>(modele.langs && modele.langs.find(o=>o.id===parseInt(id))) || {},[modele,id]);
  const [locked, setLocked] = useState(true);
  const [modLang, setModLang] = useState({});
  const [fields, setFields] = useState([]);
  useEffect(()=>{
    const currentFields=Object.keys(lang);
    if(!_.isEqual(currentFields,fields)) {
      setFields(currentFields);
    }
  },[lang,fields,setFields]);
  const validateNom=(v)=>{
    if (!v || v.length===0) return {test:false,message:'Veuillez saisir un nom'};
    return {test:true};
  }
  const valide=validateNom(modLang.name).test;
  const handleOk=()=>{
    if (!saved && valide && lang && lang.id) {
      post('langs',modLang,()=>{});
      setLocked(true);
    }
  }
  const handleChange=useCallback((k)=>
  (v)=>{
    setModLang((state)=>{return{...state,[k]:v}});
  },[setModLang]);
  const handlers=useMemo(()=>{
    const res={};
    for (const field of fields) {
      res[field]=handleChange(field);
    }
    return res;
  },[handleChange,fields]);
  useEffect(()=>{
    if(!_.isEqual(lang,lastLang.current)) {
      const newLang=_.cloneDeep(modLang);
      const patch=createPatch(lastLang.current,lang);
      applyPatch(newLang,patch);
      lastLang.current=_.cloneDeep(lang);
      setModLang(newLang);
    }
  },[lang,setModLang,modLang]);
  useEffect(()=>{
    if (!modLang.name_short) setLocked(false);
  },[modLang.name_short, setLocked])
  const saved=useMemo(()=>_.isEqual(lang,modLang),[lang,modLang]);
  return modLang.name ? <Container maxWidth="lg" className="langs">
    <div className="spacer"/>
    <Typography variant="h1" component="h1">
      Langue : {lang.name}
    </Typography>
    <div className="mini-spacer"></div>
    <Button disabled={!valide || saved} onClick={handleOk} variant="contained">Enregistrer</Button>
    <div className='spacer'></div>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Text name='Nom' autoFocus value={modLang.name || ''} handleChange={handlers['name']} handleSubmit={handleOk} validate={validateNom}/>
      </Grid>
      <Grid item xs={6}>
        <Text name='Nom court' disabled={locked} value={modLang.name_short || ''} handleChange={handlers['name_short']} handleSubmit={handleOk}/>
        {modLang.name_short && <Alert severity="warning">Changer le code langue peut empêcher l'application de fonctionner correctement. <span style={{textDecoration:'underline',cursor:'pointer'}} onClick={()=>setLocked(state=>!state)}>Cliquer ici pour activer/désactiver le champ.</span></Alert>}
      </Grid>
      </Grid>
    <div className="mini-spacer"></div>
    <Button disabled={!valide || saved} onClick={handleOk} variant="contained">Enregistrer</Button>
  </Container> : '';
}

export default Lang;
