import React, {useCallback, useContext, useState, useEffect, useRef } from "react";
import "./Upload.scss";
import {useDropzone} from 'react-dropzone'
import * as tus from "tus-js-client";
import { SettingsContext } from "providers/SettingsProvider";
import { ModelContext } from "providers/ModelProvider";
import { produce } from 'immer';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import Snackbar from '@mui/material/Snackbar';
import Conf from 'Conf';
import Typography from '@mui/material/Typography';

function Upload({value=[], name='',docId,path,maxFiles=1,type='image',volume=1,maxWidth='100%',shadow=true,handleChange}) {
  const mounted = useRef(false);
  const { uploaders, addUploaders } = useContext(SettingsContext);
  const { post, imgsReady } = useContext(ModelContext);
  const [ errors,setErrors ] = useState([]);
  const [ suffix,setSuffix ] = useState(Date.now());
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  },[]);
  useEffect(()=>{
    if(path && docId && !Array.isArray(value)) {
      console.log('value is not an array, fixing',value,path,docId);
      const tab=path.split('/');
      post(tab[0],{id:docId,[tab[1]]:[]});
    }
  },[value,docId,path,post]);
  const addFile=useCallback((f)=>{
    console.log('addFile',f);
    const { path, docId, value, ...others } = f;
    const tab=path.split('/');
    post(tab[0],{id:docId,[tab[1]]:[...value,{...others}]});
  },[post]);
  useEffect(()=>{
    setSuffix(state=>state+1);
  },[imgsReady]);
  const handleDelete=useCallback((f)=>{
    const idx=value.findIndex((o)=>o.url===f.url);
    if (idx!==-1) {
      const tab=path.split('/');
      post(tab[0],{id:docId,[tab[1]]:[...[...value].splice(0,idx),...[...value].splice(idx+1)]});
    }
  },[value,docId,path,post]);
  const onDrop = useCallback((acceptedFiles) => {
    let newUploaders=[];
    let myUploaders=uploaders.filter((o)=>o.path===path && o.docId===docId);
    acceptedFiles.forEach((file, i) => {
      // Create a new tus upload
      if (value.length+myUploaders.length+i<maxFiles) {
        let upload = new tus.Upload(file, {
          endpoint: Conf.apiUrl+'upload',
          onError: (error)=>{
            console.log("Failed because: " + error);
          },
          retryDelays: [0, 3000, 5000, 10000, 20000],
          metadata: {
            filename: file.name,
            filetype: file.type
          },
        });
        newUploaders.push(upload);
        // Check if there are any previous uploads to continue.
      } else {
        console.log('Trop de fichiers, nombre max autorisé : '+maxFiles);
        setErrors(errors=>[...errors,'Trop de fichiers, nombre max autorisé : '+maxFiles]);
      }
    });
    addUploaders(newUploaders,docId,path,value,addFile);
  },[addUploaders, addFile, maxFiles,docId,path,value,uploaders]);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  const handleCloseSnack=()=>{
    setErrors(produce(errors,(draft)=>{
      draft.splice(0,1);
    }))
  }
  return (Array.isArray(value)) ?
    <div className="input-upload">
      {name && <Typography variant="h5" component="h5" className='input-upload-name'>
        {name}
      </Typography>}
      {value.length<maxFiles && <div className={'upload-drop-zone'+(isDragActive ? ' active' : '')} {...getRootProps()}>
        <input {...getInputProps()} />
        <p>Déposez les fichiers ici, ou cliquez pour selectionner des fichiers</p>
      </div>}
      {value.slice(0).reverse().map((file,i)=><div key={i}>
        {file && type==='image' ? <>
          <div className="preview-container" style={{maxWidth}}>
            <div className="preview">
              {file.type==='image/svg+xml' ?
              <img src={Conf.filesUrl+file.url} alt="" className={shadow ? 'shadow' : null} crossOrigin="anonymous"/>
              : <img key={suffix} src={Conf.filesUrl+file.url+'-600'} alt="" className={shadow ? 'shadow' : null} crossOrigin="anonymous"/>}
            </div>
          </div>
          <div className="name">
          <span>{file.name}</span> <a href={Conf.filesUrl+file.url} target="_blanc">
          <IconButton
            size="small"
            color="inherit"
          >
            <LinkIcon />
          </IconButton>
          </a>
          <IconButton
            size="small"
            color="inherit"
            onClick={()=>handleDelete(file)}
          >
            <DeleteIcon />
          </IconButton>
          </div>
        </>:null}
        {file && ['image','audio','video'].indexOf(type)===-1 ? <div className="name">
          <span>{file.name}</span> <a href={Conf.filesUrl+file.url} target="_blanc">
          <IconButton
            size="small"
            color="inherit"
          >
            <LinkIcon />
          </IconButton>
          </a>
          <IconButton
            size="small"
            color="inherit"
            onClick={()=>handleDelete(file)}
          >
            <DeleteIcon />
          </IconButton>
          </div>:null}
        </div>
      )}
      <Snackbar
        open={errors.length>0}
        onClose={handleCloseSnack}
        message={errors[0]}
        autoHideDuration={5000}
      />
    </div>
  : null;
}

export default Upload;
