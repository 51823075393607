import React, {useContext} from "react";
import "./Home.scss";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { AuthContext } from "providers/AuthProvider";
import Conf from 'Conf';
import { saveAs } from 'file-saver';
import Button from '@mui/material/Button';

function Home() {
  const { auth } = useContext(AuthContext);
  const getZip=()=>{
    fetch(Conf.apiUrl+'data.zip', {
      method: 'GET', // or 'PUT'
      headers: {
        Authorization: 'Bearer '+auth.token,
      },
    })
    .then((response) => response.blob())
    .then((blob) => {
      saveAs(blob, `data.zip`,{ autoBom: true });
    });
  }
  return <Container maxWidth="lg">
    <div className="spacer"/>
    <Typography variant="h2" component="h2">
      Bonjour <strong>{auth.name}</strong>
    </Typography>
    <div className='spacer'></div>
    <Button variant="contained" onClick={getZip}>Télécharger le zip</Button>
    <div className='spacer'></div>
    <Alert severity="info">Pour récupérer les dernières modifications, ne pas oublier de cliquer sur le bouton publier avant de télécharger le zip.</Alert>
    <div className='big-spacer'></div>
  </Container>;
}

export default Home;
