import React, { useState, useContext, useEffect, useMemo, useRef, useCallback } from "react";
import "./Profil.scss";
import Button from '@mui/material/Button';
import Text from 'components/form/Text';
import Select from 'components/form/Select';
import Upload from 'components/form/Upload';
import Itinerary from 'components/form/Itinerary';
import Langswitch from 'components/Langswitch';
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Utils from 'Utils/Utils';
import QRCode from "react-qr-code";
import _ from 'lodash';
import {
  useParams,
} from "react-router-dom";
import { createPatch, applyPatch } from 'rfc6902';
import countries from 'i18n-iso-countries';
import french from "i18n-iso-countries/langs/fr.json"
countries.registerLocale(french);
const allCountries=countries.getNames("fr", {select: "official"});
const options=Object.keys(allCountries).map((k)=>{return{label:allCountries[k],value:k}});
const imageDownload = (e,filename) => {
  const svg=e.currentTarget;
  const svgData = new XMLSerializer().serializeToString(svg);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const img = new Image();
  img.onload = () => {
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);
    const pngFile = canvas.toDataURL("image/png");
    const downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = `${pngFile}`;
    downloadLink.click();
  };
  img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
};

function Profil() {
  const lastProfil=useRef({});
  const lastTraductions=useRef([]);
  const { id }=useParams();
  const { post, modele } = useContext(ModelContext);
  const { appState, setAppState } = useContext(SettingsContext);
  const profil=useMemo(()=>(modele.profils && modele.profils.find(o=>o.id===parseInt(id))) || {},[modele,id]);
  const traductions=useMemo(()=>(modele.traductions && modele.traductions.filter(o=>o.collection==="profils" && o.id_objet===parseInt(id))) || [],[modele,id]);
  const [modTraductions, setModTraductions] = useState([]);
  const [modProfil, setModProfil] = useState({});
  const [fields, setFields] = useState([]);
  const tabIndex=useMemo(()=>appState.tabIndexes[id]||0,[id,appState]);
  const setTabIndex=useCallback((tabIndex)=>setAppState(state=>{
    return {...state,tabIndexes:{...state.tabIndexes,[id]:tabIndex}}
  }),[id,setAppState]);
  useEffect(()=>{
    const currentFields=Object.keys(profil);
    if(!_.isEqual(currentFields,fields)) {
      setFields(currentFields);
    }
  },[profil,fields,setFields]);
  const validateNom=(v)=>{
    if (!v || v.length===0) return {test:false,message:'Veuillez saisir un nom'};
    return {test:true};
  }
  const valide=validateNom(modProfil.name).test;
  const handleOk=()=>{
    if (!saved && valide && profil && profil.id) post('profils',modProfil,()=>{});
    if (!savedTraductions && traductions && profil.id) {
      for(const tr of modTraductions) {
        if (!tr.id) post('traductions',tr,()=>{});
        else {
          const trOrig=traductions.find(o=>o.id===tr.id);
          if (tr.value!==trOrig.value) post('traductions',tr,()=>{});
        }
      }
    }
  }
  const handleChange=useCallback((k)=>
  (v)=>{
    console.log('handleChange fired');
    setModProfil((state)=>{return{...state,[k]:v}});
  },[setModProfil]);
  const handlers=useMemo(()=>{
    const res={};
    for (const field of fields) {
      res[field]=handleChange(field);
    }
    return res;
  },[handleChange,fields]);
  useEffect(()=>{
    if(!_.isEqual(profil,lastProfil.current)) {
      const newProfil=_.cloneDeep(modProfil);
      const patch=createPatch(lastProfil.current,profil);
      applyPatch(newProfil,patch);
      lastProfil.current=_.cloneDeep(profil);
      setModProfil(newProfil);
    }
  },[profil,modProfil,setModProfil]);
  useEffect(()=>{
    if(!_.isEqual(traductions,lastTraductions.current)) {
      const newTraductions=_.cloneDeep(modTraductions);
      const patch=createPatch(lastTraductions.current,traductions);
      applyPatch(newTraductions,patch);
      lastTraductions.current=_.cloneDeep(traductions);
      setModTraductions(newTraductions.filter((o,i,tab)=>{
        if(o.id) return true;
        for(const item of tab) {
          if(
            item.id
            && o.id_lang===item.id_lang
            && o.collection===item.collection
            && o.id_objet===item.id_objet
            && o.key===item.key
          ) return false;
        }
        return true;
      }));
    }
  },[traductions,setModTraductions,modTraductions]);
  const name=profil.forename ? profil.forename+' '+profil.name : profil.name;
  const saved=useMemo(()=>_.isEqual(profil,modProfil),[profil,modProfil]);
  const savedTraductions=useMemo(()=>{
    let test=true;
    for (const tr of modTraductions) {
      const orig=traductions.find(o=>o.id_lang===tr.id_lang && o.key===tr.key);
      if (!orig || orig.value!==tr.value) test=false;
    }
    return test;
  },[traductions,modTraductions]);
  return modProfil.name ? <Container maxWidth="lg" className="profils">
    <div className="spacer"/>
    <Typography variant="h1" component="h1">
      Profil : {name}
    </Typography>
    <div className="mini-spacer"></div>
    <Tabs value={tabIndex} onChange={(e,v)=>setTabIndex(v)} aria-label="basic tabs example">
      <Tab label="Infos"/>
      <Tab label="Itinéraire"/>
      <Tab label="QRcodes"/>
    </Tabs>
    <div className="spacer"/>
    {tabIndex===0 && <>
      <Button disabled={!valide || (saved && savedTraductions)} onClick={handleOk} variant="contained">Enregistrer</Button>
      <div className='spacer'></div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Text name='Nom' autoFocus value={modProfil.name || ''} handleChange={handlers['name']} handleSubmit={handleOk} validate={validateNom}/>
          <Text name='Prénom' value={modProfil.forename || ''} handleChange={handlers['forename']} handleSubmit={handleOk}/>
          <Text name='Date de Naissance' value={modProfil.birth_date} handleChange={handlers['birth_date']} handleSubmit={handleOk}/>
          <Select name='Nationalité' value={modProfil.nationality || ''} handleChange={handlers['nationality']} options={options}/>
          <Langswitch
          idObjet={profil.id}
          tradKey="profession"
          collection="profils"
          type="text"
          name="Profession"
          traductions={modTraductions}
          onChange={setModTraductions}
          />
        </Grid>
        <Grid item xs={6}>
          <Upload name='Portrait' value={modProfil.portrait || []} path="profils/portrait" docId={id} type="image" handleChange={handlers['portrait']}/>
        </Grid>
        <Grid item xs={12}>
        <Langswitch
          idObjet={profil.id}
          tradKey="biography"
          collection="profils"
          type="richText"
          name="Biographie"
          traductions={modTraductions}
          onChange={setModTraductions}
          />
        </Grid>
      </Grid>
      <div className="mini-spacer"></div>
      <Button disabled={!valide || (saved && savedTraductions)} onClick={handleOk} variant="contained">Enregistrer</Button>
    </>}
    {tabIndex===1 && <>
      <div className='spacer'></div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Itinerary name='Itinéraire' value={modProfil.itinerary || ''} profilId={parseInt(id)}/>
        </Grid>
      </Grid>
      <div className="mini-spacer"></div>
    </>}
    {tabIndex===2 && <>
      <div className='spacer'></div>
      <Grid container spacing={2}>
        {modele.langs.map((l)=><Grid key={l.id} item xs={12} md={4}>
          <div className='qrcode-container'>
            <div className='lang'>{l.name_short}</div>
            <QRCode value={JSON.stringify({id:parseInt(id),lang:l.name_short})} onClick={(e)=>imageDownload(e,`${Utils.filter(profil.name)}_${Utils.filter(profil.forename)}_${l.name_short}`)}/>
          </div>
        </Grid>)}
      </Grid>
      <div className="mini-spacer"></div>
    </>}
  </Container> : '';
}

export default Profil;
