import React, {useContext, useState, useCallback, useMemo} from "react";
import "./Profils.scss";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ProfilAdd from 'components/Profil/ProfilAdd';
import { confirm } from 'components/Confirm'
import Fab from '@mui/material/Fab';
import {
  NavLink,
} from "react-router-dom";
import Text from 'components/form/Text';
import Utils from 'Utils/Utils';
import Pagination from '@mui/material/Pagination';

const itemsPerPage=12;

function Profils() {
  const { modele,del } = useContext(ModelContext);
  const { appState,setAppState } = useContext(SettingsContext);
  const [ add, setAdd ] = useState(false);
  const profils=useMemo(()=>modele.profils || [],[modele]);
  const { profilsFilter, profilsPage } = appState;
  const setProfilsFilter=useCallback((profilsFilter)=>{
    setAppState(state=>{return{...state,profilsFilter,profilsPage:1}});
  },[setAppState]);
  const setProfilsPage=useCallback((e,profilsPage)=>{
    setAppState(state=>{return{...state,profilsPage}});
  },[setAppState]);
  const handleAddProfil=()=>{
    setAdd(true);
  }
  const tile=useCallback((profil)=>{
    const handleDeleteProfil=(profil)=>{
      confirm({
        titre: 'Supprimer le profil ?',
        confirmation: <>Êtes vous sûr de vouloir supprimer <strong>{profil.Nom}</strong>&nbsp;?</>,
      }).then((ok)=>{
        if (ok) del('profils',profil.id);
        else console.log('canceled');
      });
    };
    const name=profil.forename ? profil.forename+' '+profil.name : profil.name;
    return <Grid key={profil.id} item xs={6} md={4} lg={3}>
      <Card>
        <CardContent className="sort-handle profil-content">
          <Typography variant="body">
            {name || 'Sans nom'}
          </Typography>
        </CardContent>
        <CardActions>
          <NavLink to={'/profil/'+profil.id}><Button size="small">Modifier</Button></NavLink>
          <Button size="small" onClick={()=>handleDeleteProfil(profil)}>Supprimer</Button>
        </CardActions>
      </Card>
    </Grid>;
  },[del]);
  const filteredProfils=useMemo(()=>{
    let tab = (profilsFilter ? profilsFilter.split(' ') : []).filter((o)=>o!=='').map(word=>Utils.accentsTidyLw(word));
    return profils.filter((o)=>{
      let test = true;
      for (const word of tab) {
        test = test && (
          Utils.accentsTidyLw(o.name||'').indexOf(word) !==-1
          || Utils.accentsTidyLw(o.forename||'').indexOf(word) !==-1
        );
      };
      return test;
    });
  },[profils,profilsFilter]);
  const nb=useMemo(()=>Math.ceil(filteredProfils.length/itemsPerPage),[filteredProfils]);
  return <>
    <Container maxWidth="lg" className="profils">
      <div className="spacer"/>
      <Typography variant="h1" component="h1">
        Profils
      </Typography>
      <div className='spacer'></div>
      <Text name='Rechercher' autoFocus value={profilsFilter} handleChange={setProfilsFilter} clear/>
      <div className='spacer'></div>
      <Pagination size='small' count={nb} page={profilsPage} onChange={setProfilsPage}/>
      <div className='spacer'></div>
      <Grid container spacing={2}>
        {filteredProfils.filter((o,i)=>i>=(profilsPage-1)*itemsPerPage && i<profilsPage*itemsPerPage).map((profil)=>tile(profil))}
      </Grid>
      <div className='spacer'></div>
      <Pagination size='small' count={nb} page={profilsPage} onChange={setProfilsPage}/>
    </Container>
    <div className="floating-action">
      <Container maxWidth="lg">
      <Fab color="primary" aria-label="add" onClick={handleAddProfil}>
        <AddIcon />
      </Fab>
      </Container>
    </div>
    <ProfilAdd open={add} handleClose={()=>setAdd(false)}/>
  </>
}

export default Profils;
