import React, {useContext,useState} from "react";
import "./Topbar.scss";
import { AuthContext } from "providers/AuthProvider";
import { SettingsContext } from "providers/SettingsProvider";
import { ModelContext } from "providers/ModelProvider";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {
  NavLink,
} from "react-router-dom";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';

function Topbar() {
  const { auth, onLoggedOut } = useContext(AuthContext);
  const { screenSize } = useContext(SettingsContext);
  const { post } = useContext(ModelContext);
  const [ mobileMenu,setMobileMenu] = useState(false);
  const [ publishing,setPublishing] = useState(false);
  const handlePublish=()=>{
    setPublishing(true);
    post('publish',{},()=>{
      setPublishing(false);
    });
  }
  return (
    <>
    <AppBar position="static" className="topbar" color="primary">
      <Toolbar>
        {['xs','sm','md','lg'].indexOf(screenSize)!==-1 && <>
          <IconButton aria-label="menu" onClick={()=>setMobileMenu(true)} color="inherit">
            <MenuIcon />
          </IconButton>
        </>}
        <NavLink to="/"><span className="topbar-logo"/></NavLink>
        {['xs','sm','md','lg'].indexOf(screenSize)===-1 && <>
          <Typography variant="h6" component="div" className="topbar-item">
            <NavLink to="/">Accueil</NavLink>
          </Typography>
          <Typography variant="h6" component="div" className="topbar-item">
            {['admin','user'].indexOf(auth.role)!==-1 && <NavLink to="/profils">Profils</NavLink>}
          </Typography>
          <Typography variant="h6" component="div" className="topbar-item">
            {['admin','user'].indexOf(auth.role)!==-1 && <NavLink to="/places">Lieux</NavLink>}
          </Typography>
          <Typography variant="h6" component="div" className="topbar-item">
            {['admin','user'].indexOf(auth.role)!==-1 && <NavLink to="/langs">Langues</NavLink>}
          </Typography>
          <Typography variant="h6" component="div" className="topbar-item">
            {auth.role==='admin' && <NavLink to="/utilisateurs">Utilisateurs</NavLink>}
          </Typography>
          <Typography variant="h6" component="div" className="topbar-item">
            {['user'].indexOf(auth.role)!==-1 && <NavLink to="/moi">Mon compte</NavLink>}
          </Typography>
        </>}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className="topbar-item publish">
          <Button variant='contained' disabled={publishing} onClick={handlePublish}>Publier</Button>
        </Typography>
        <div className="topbar-user"><small>{auth.name}</small></div>
        <IconButton aria-label="menu" onClick={onLoggedOut} color="inherit">
          <LogoutIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
    <Drawer
      anchor='left'
      open={mobileMenu}
      onClose={()=>setMobileMenu(false)}
      className='topbar-drawer'
    >
      <div className='topbar-drawer-header'>
        <IconButton aria-label="menu" onClick={()=>setMobileMenu(false)} color="inherit">
          <MenuIcon />
        </IconButton>
      </div>
      <List onClick={()=>setMobileMenu(false)}>
        <NavLink to="/">
          <ListItem button>
            <ListItemText primary="Accueil" />
          </ListItem>
        </NavLink>
        {auth.role==='admin' && <NavLink to="/utilisateurs">
          <ListItem button>
            <ListItemText primary="Utilisateurs" />
          </ListItem>
        </NavLink>}
        {['admin','user'].indexOf(auth.role)!==-1 && <NavLink to="/profils">
          <ListItem button>
            <ListItemText primary="Profils" />
          </ListItem>
        </NavLink>}
        {['admin','user'].indexOf(auth.role)!==-1 && <NavLink to="/places">
          <ListItem button>
            <ListItemText primary="Lieux" />
          </ListItem>
        </NavLink>}
        {['user'].indexOf(auth.role)!==-1 && <NavLink to="/moi">
          <ListItem button>
            <ListItemText primary="Mon compte" />
          </ListItem>
        </NavLink>}
      </List>
    </Drawer>
    </>
  )
}

export default Topbar;
