import React, { useState, createContext, useContext, useEffect, useCallback, useRef } from "react";
import Conf from 'Conf';
import { AuthContext } from "providers/AuthProvider";
import { produce } from 'immer';
import axios from 'axios';
import _ from 'lodash';
import { io } from "socket.io-client";
import Utils from 'Utils/Utils';

let fresh=true;

export const ModelContext = createContext({});
const updateCollection=(modele,context,items)=>{
  const {type,sort,order} = context;
  const newModele=produce(modele,(draft)=>{
    draft[type]=items;
    if (sort && order) draft[type]=_.orderBy(draft[type],sort,order);
    if (sort && !order) draft[type]=_.sortBy(draft[type],sort);
  });
  if (!modele[type] || !_.isEqual(modele[type], newModele[type])) {
    console.log('model change',type);
    return newModele;
  } else {
    return modele;
  }
};
const updateItems=(modele,context,items)=>{
  const {type,sort,order} = context;
  const newModele=produce(modele,(draft)=>{
    if (!draft[type]) draft[type]=[];
    items.forEach((item) => {
      const idx=draft[type].findIndex((o)=>o.id===item.id);
      if (item._del) {
        if(idx!==-1) draft[type].splice(idx,1);
      } else {
        if (idx===-1) draft[type].push(item);
        else draft[type][idx]={...draft[type][idx],...item};
      }
    });
    if (sort && order) draft[type]=_.orderBy(draft[type],sort,order);
    if (sort && !order) draft[type]=_.sortBy(draft[type],sort);
  });
  if (!modele[type] || !_.isEqual(modele[type], newModele[type])) {
    console.log('model change',type);
    return newModele;
  } else {
    return modele;
  }
};

const parseCustomPath=(customPath,doc,createItem=false)=>{
  //console.log(customPath,doc);
  let p=[...customPath];
  let path=[];
  while (p.length>0) {
    let current=p[0];
    let el=path.length>0 ? _.get(doc,path) : doc;
    if (el) {
      if (Array.isArray(current)) {
        let id=current[0];
        let idKey=current[1] || '_id';
        if (Array.isArray(el)) {
          let idx=el.findIndex((o)=>o[idKey]===id);
          if (idx!==-1) path.push(idx);
          else {
            if (createItem) {
              path.push(el.length);
              el.push({[idKey]:id});
            } else {
              console.log('not found');
              return false;
            }
          }
        } else {
          console.log('not found');
        }
      } else {
        path.push(current)
      }
    } else {
      console.log('not found');
    }
    p.splice(0,1);
  }
  //console.log('parseCustomPath',customPath,path);
  return path;
};
const removePath=(doc,path)=>{
  if (path.length<=1) {
    _.unset(doc,path);
  } else {
    let item=_.get(doc,path);
    let parent=_.get(doc,path.slice(0,-1));
    if (Array.isArray(parent)) {
      let idx=parent.indexOf(item);
      parent.splice(idx,1);
    } else {
      _.unset(doc,path);
    }
  }
};
const get=(o,path)=>{
  return _.get(o,parseCustomPath(path,o));
}
const socket = io(Conf.apiUrl);

const ASSETS_CACHE_NAME = 'v1-compiegne';

const schema=[
  {type:'users',roles:['admin','user'],params:{},sort:['id']},
  {type:'me',roles:['user'],params:{},sort:['id']},
  {type:'profils',roles:['admin','user'],params:{},sort:[(o)=>Utils.accentsTidyLw(o.name),(o)=>Utils.accentsTidyLw(o.forename)]},
  {type:'places',roles:['admin','user'],params:{},sort:[(o)=>Utils.accentsTidyLw(o.name)]},
  {type:'stops',roles:['admin','user'],params:{},sort:['profilId','index']},
  {type:'traductions',roles:['admin','user'],params:{},sort:['id']},
  {type:'langs',roles:['admin','user'],params:{},sort:['id']},
];

const ModelProvider = ({children})=>{
  const { auth,renew } = useContext(AuthContext);
  const [ modele,setModele ] = useState({});
  const [ initDone,setInitDone ] = useState(false);
  const [ sid,setSid ] = useState(null);
  const [ messages,setMessages ] = useState([]);
  const [ imgsReady,setImgsReady ] = useState([]);
  const [ contexts,setContexts ] = useState(schema);
  const cachedModel=useRef(null);
  const userId=useRef(0);
  useEffect(()=>{
    if(auth.id!==userId.current) {
      userId.current=auth.id;
      const getFromCache=async ()=>{
        //retrieve modele from cache
        const request = new Request(Conf.apiUrl+userId.current+'/model',{method:'GET'});
        const cache= await caches.open(ASSETS_CACHE_NAME);
        const res = await cache.match(request);
        if (res) {
          const data = await res.json();
          console.log('Model init',auth.id,data);
          cachedModel.current=data;
          setModele(data);
        }
        setInitDone(true);
      };
      if (auth.id) getFromCache();
      else {
        setModele({});
        setInitDone(false);
        fresh=true;
      }
    }
  },[setModele,auth]);
  useEffect(()=>{
    const handleConnect=() => {
      console.log(socket.id); // x8WIv7-mJelg7on_ALbx
      setSid(socket.id);
    };
    const handleDisconnect=() => {
      console.log(socket.id); // x8WIv7-mJelg7on_ALbx
      setSid(socket.id);
    };
    socket.on("connect", handleConnect);
    socket.on("disconnect", handleDisconnect);
    return ()=>{
      socket.off("connect", handleConnect);
      socket.off("disconnect", handleDisconnect);
    }
  },[setSid]);
  const contextsCache=useRef(contexts);
  useEffect(()=>{
    if(initDone && cachedModel.current!==modele) {
      if (Object.keys(modele).length>0) {
        const string= JSON.stringify(modele);
        const options = { status: 200, statusText: 'OK' };
        const response = new Response(string, options);
        const request = new Request(Conf.apiUrl+userId.current+'/model',{method:'GET'});
        caches.open(ASSETS_CACHE_NAME)
        .then(cache => {
          console.log('Model store',modele);
          cache.put(request, response);
        });
      }
    }
  },[modele,initDone]);
  const getType=useCallback((context)=>{
    axios.get(Conf.apiUrl+context.type, { params : context.params, headers: { Authorization: 'Bearer '+auth.token}}).then((res)=>{
      setModele((state)=>updateCollection(state,context,res.data.res));
      if(context.type==='me') renew();
    }).catch((error)=>{
      console.log(error);
    });
  },[setModele,auth.token,renew]);
  const updateType=useCallback((context)=>{
    axios.get(Conf.apiUrl+context.type, { params : context.params, headers: { Authorization: 'Bearer '+auth.token}}).then((res)=>{
      setModele((state)=>updateItems(state,context,res.data.res));
      if(context.type==='me') renew();
    }).catch((error)=>{
      console.log(error);
    });
  },[setModele,auth.token,renew]);
  useEffect(()=>{
    const handleMessage=(msg) => {
      if (msg.toUpdate && msg.toUpdate.length>0) {
        msg.toUpdate.forEach((type) => {
          console.log('update',type);
          if(typeof type === 'string') {
            const context=contexts.find((o)=>o.type===type);
            if (context) getType(context);
          } else {
            if (type.collectionName) {
              const {collectionName,ids}=type;
              const context=contexts.find((o)=>o.type===collectionName);
              if (context) {
                if (ids) updateType({...context, params:{...context.params,ids}});
                else getType(context);
              }
            }
          }
        });
      }
      if (msg.toDelete && msg.toDelete.length>0) {
        msg.toDelete.forEach((type) => {
          console.log('delete',type);
          const {collectionName,ids}=type;
          const context=contexts.find((o)=>o.type===collectionName);
          if (context) setModele((state)=>updateItems(state,context,ids.map((id)=>{return{id,_del:true}})));
        });
      }
      if (msg.imgReady) {
        setImgsReady(state=>{return [...state,msg.imgReady]});
      }
    };
    socket.on("message", handleMessage);
    return ()=>{
      socket.off("message", handleMessage);
    }
  },[getType,updateType,contexts]);
  useEffect(()=>{
    if (auth.id && fresh && initDone) {
      fresh=false;
      contexts.forEach((context)=>{
        if (context.roles.indexOf(auth.role)!==-1) {
          getType(context);
        }
      });
    }
  },[auth,contexts,getType,initDone]);
  useEffect(()=>{
    contexts.forEach((context)=>{
      const cache=contextsCache.current.find((o)=>o.type===context.type);
      if (context.roles.indexOf(auth.role)!==-1 && cache && !_.isEqual(context.params,cache.params)) {
        getType(context);
      }
    });
  },[auth,contexts,getType]);
  const getDoc=useCallback((type,docId)=>{
    return get(modele,[type,[docId,'id']]);
  },[modele]);
  const getDocPath=(type,docId,path)=>{
    const doc=getDoc(type,docId);
    return get(doc,path);
  }
  const post=(s,payload,cb=()=>{},raw=false,responseType='json')=>{
    let [ type, route ] = s.split('|');
    if (!route) route=type;
    console.log(type,route);
    axios.post(Conf.apiUrl+route, {...payload, _sid:sid},{ headers: { Authorization: 'Bearer '+auth.token},responseType}).then((res)=>{
      if (res.data.res) {
        const context=contexts.find((o)=>o.type===type);
        setModele((state)=>updateItems(state,context,res.data.res));
      }
      if (res.data.messages) {
        setMessages((state)=>{return [...state,...res.data.messages]});
      }
      if (res.data.refresh) {
        res.data.refresh.forEach((item) => {
          if(typeof item==='string' ) {
            const context=contexts.find((o)=>o.type===item);
            if (context) getType(context);
          }
          if (typeof item==='object') {
            const { collectionName,ids }=item;
            if (collectionName) {
              const context=contexts.find((o)=>o.type===collectionName);
              if (ids) updateType({...context, params:{...context.params,ids}});
              else getType(context);
            }
          }
        });
      }
      if (raw) cb(res);
      else cb(res.data.res);
      if (type==='me') renew();
      if (type==='users' && payload.id===auth.id) renew();
    }).catch((error)=>{
      console.log(error);
      setMessages((state)=>{return [...state,{text:'Problème de connexion'}]});
    });
  }
  const del=useCallback((type,id,cb=()=>{})=>{
    axios.delete(Conf.apiUrl+type+'/'+id, { headers: { Authorization: 'Bearer '+auth.token}, data:{_sid:sid}}).then((res)=>{
      if (res.data.res) {
        const context=contexts.find((o)=>o.type===type);
        setModele((state)=>updateItems(state,context,res.data.res));
      }
      if (res.data.messages) {
        setMessages((state)=>{return [...state,...res.data.messages]})
      }
      cb();
    }).catch((error)=>{
      console.log(error);
      setMessages((state)=>{return [...state,{text:'Problème de connexion'}]});
    });
  },[setMessages,setModele,auth.token,sid,contexts]);
  const savePath=(type,docId,path,value)=>{
    const doc=getDoc(type,docId);
    const saveDoc=produce(doc,(draft)=>{
      if (value===null) removePath(draft,parseCustomPath(path,draft,true));
      else _.set(draft,parseCustomPath(path,draft,true),value);
    });
    const payload={id:doc.id};
    Object.keys(doc).forEach((k) => {
      if (doc[k]!==saveDoc[k]) payload[k]=saveDoc[k];
    });
    console.log('doSave',type,payload);
    post(type,payload);
  }
  return (
        <ModelContext.Provider value={{messages,setMessages,modele,getDoc,getDocPath,savePath,post,del,setContexts,imgsReady}}>
            {children}
        </ModelContext.Provider>
    );
}
export default ModelProvider;
