import React, {useContext, useState, useCallback, useMemo} from "react";
import "./Langs.scss";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import LangAdd from 'components/Lang/LangAdd';
import { confirm } from 'components/Confirm'
import Fab from '@mui/material/Fab';
import {
  NavLink,
} from "react-router-dom";
import Text from 'components/form/Text';
import Utils from 'Utils/Utils';
import Pagination from '@mui/material/Pagination';

const itemsPerPage=12;

function Langs() {
  const { modele,del } = useContext(ModelContext);
  const { appState,setAppState } = useContext(SettingsContext);
  const [ add, setAdd ] = useState(false);
  const langs=useMemo(()=>modele.langs || [],[modele]);
  const { langsFilter, langsPage } = appState;
  const setLangsFilter=useCallback((langsFilter)=>{
    setAppState(state=>{return{...state,langsFilter,langsPage:1}});
  },[setAppState]);
  const setLangsPage=useCallback((e,langsPage)=>{
    setAppState(state=>{return{...state,langsPage}});
  },[setAppState]);
  const handleAddLang=()=>{
    setAdd(true);
  }
  const tile=useCallback((lang)=>{
    const handleDeleteLang=(lang)=>{
      confirm({
        titre: 'Supprimer la lang ?',
        confirmation: <>Êtes vous sûr de vouloir supprimer <strong>{lang.Nom}</strong>&nbsp;?</>,
      }).then((ok)=>{
        if (ok) del('langs',lang.id);
        else console.log('canceled');
      });
    };
    return <Grid key={lang.id} item xs={6} md={4} lg={3}>
      <Card>
        <CardContent className="sort-handle lang-content">
          <Typography variant="body">
            {lang.name || 'Sans nom'} ({lang.name_short})
          </Typography>
        </CardContent>
        <CardActions>
          <NavLink to={'/lang/'+lang.id}><Button size="small">Modifier</Button></NavLink>
          <Button size="small" onClick={()=>handleDeleteLang(lang)}>Supprimer</Button>
        </CardActions>
      </Card>
    </Grid>;
  },[del]);
  const filteredLangs=useMemo(()=>{
    let tab = (langsFilter ? langsFilter.split(' ') : []).filter((o)=>o!=='').map(word=>Utils.accentsTidyLw(word));
    return langs.filter((o)=>{
      let test = true;
      for (const word of tab) {
        test = test && (
          Utils.accentsTidyLw(o.name||'').indexOf(word) !==-1
          || Utils.accentsTidyLw(o.name_short||'').indexOf(word) !==-1
        );
      };
      return test;
    });
  },[langs,langsFilter]);
  const nb=useMemo(()=>Math.ceil(filteredLangs.length/itemsPerPage),[filteredLangs]);
  return <>
    <Container maxWidth="lg" className="langs">
      <div className="spacer"/>
      <Typography variant="h1" component="h1">
        Langues
      </Typography>
      <div className='spacer'></div>
      <Text name='Rechercher' autoFocus value={langsFilter} handleChange={setLangsFilter} clear/>
      <div className='spacer'></div>
      <Pagination size='small' count={nb} page={langsPage} onChange={setLangsPage}/>
      <div className='spacer'></div>
      <Grid container spacing={2}>
        {filteredLangs.filter((o,i)=>i>=(langsPage-1)*itemsPerPage && i<langsPage*itemsPerPage).map((lang)=>tile(lang))}
      </Grid>
      <div className='spacer'></div>
      <Pagination size='small' count={nb} page={langsPage} onChange={setLangsPage}/>
    </Container>
    <div className="floating-action">
      <Container maxWidth="lg">
      <Fab color="primary" aria-label="add" onClick={handleAddLang}>
        <AddIcon />
      </Fab>
      </Container>
    </div>
    <LangAdd open={add} handleClose={()=>setAdd(false)}/>
  </>
}

export default Langs;
