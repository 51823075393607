import React, { useState, useContext, useCallback, useMemo, useRef, useEffect } from "react";
import "./Itinerary.scss";
import Button from '@mui/material/Button';
import Select from 'components/form/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ModelContext } from "providers/ModelProvider";
import { createPatch, applyPatch } from 'rfc6902';
import _ from 'lodash';
import Conf from 'Conf';
import Text from 'components/form/Text';

function StopMod({id,handleClose}) {
  const lastStop=useRef({});
  const { post, modele } = useContext(ModelContext);
  const places=useMemo(()=>modele.places || [],[modele]);
  const stop=useMemo(()=>(modele.stops && modele.stops.find(o=>o.id===parseInt(id))) || {},[modele,id]);
  const [modStop, setModStop] = useState({});
  const [fields, setFields] = useState([]);
  useEffect(()=>{
    const currentFields=Object.keys(stop);
    if(!_.isEqual(currentFields,fields)) {
      setFields(currentFields);
    }
  },[stop,fields,setFields]);
  const handleOk=()=>{
    if (stop && stop.id) post('stops',modStop,()=>{
      handleClose();
    });
  }
  const handleChange=useCallback((k)=>
  (v)=>{
    console.log('handleChange fired');
    setModStop((state)=>{return{...state,[k]:v}});
  },[setModStop]);
  const handlers=useMemo(()=>{
    const res={};
    for (const field of fields) {
      res[field]=handleChange(field);
    }
    return res;
  },[handleChange,fields]);
  useEffect(()=>{
    if(!_.isEqual(stop,lastStop.current)) {
      const newStop=_.cloneDeep(modStop);
      const patch=createPatch(lastStop.current,stop);
      applyPatch(newStop,patch);
      lastStop.current=_.cloneDeep(stop);
      setModStop(newStop);
    }
  },[stop,modStop]);
  const saved=useMemo(()=>_.isEqual(stop,modStop),[stop,modStop]);
  return <Dialog open={Boolean(id)} onClose={handleClose} fullWidth maxWidth='xs'>
      {Boolean(id) && <>
        <DialogTitle>Modifier l'étape</DialogTitle>
        <DialogContent>
          <div className="mini-spacer"></div>
          <Select name='Lieu' value={modStop.placeId || ''} handleChange={handlers['placeId']} options={places.map(o=>{return{value:o.id,label:o.name}})}/>
          <Select name='Type' value={modStop.type} handleChange={handlers['type']} options={Conf.stopTypes}/>
          {modStop.type==='arrest' && <>
            <Text name="Date d'arrestation" value={modStop.beginning_date} handleChange={handlers['beginning_date']} handleSubmit={handleOk}/>
          </>}
          {['impris','kommando'].indexOf(modStop.type)!==-1 && <>
            <Text name='Date de début' value={modStop.beginning_date} handleChange={handlers['beginning_date']} handleSubmit={handleOk}/>
            <Text name='Date de fin' value={modStop.end_date} handleChange={handlers['end_date']} handleSubmit={handleOk}/>
            <Text name='Numéro de matricule' value={modStop.register_number || ''} handleChange={handlers['register_number']} handleSubmit={handleOk}/>
          </>}
          <div className="mini-spacer"></div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">Annuler</Button>
          <Button disabled={saved} onClick={handleOk} variant="contained">Enregistrer</Button>
        </DialogActions>
      </>}
    </Dialog>;
}

export default StopMod;
