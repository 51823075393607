import { createTheme } from '@mui/material/styles';
import { frFR } from '@mui/material/locale';
const theme = createTheme({},frFR);
theme.typography.h1.fontSize='3rem';
theme.typography.h3[theme.breakpoints.down('md')]= {
  fontSize: '2.5rem',
};
theme.typography.h3.fontSize='1.5rem';
theme.typography.h3[theme.breakpoints.down('md')]= {
  fontSize: '1.2rem',
};
theme.typography.h4.fontSize='1.2rem';
theme.typography.h4[theme.breakpoints.down('md')]= {
  fontSize: '1rem',
};

const Utils={
    theme,
    accentsTidy:function(s='') {
        var map = [
            ["[àáâãäåāăąǎǟǡǻȁȃȧ]", "a"],
            ["[æ]", "ae"],
            ["[ƀƃɓ]", "b"],
            ["[çćĉċčƈȼɕ]", "c"],
            ["[ďđƌȡɖɗ]", "d"],
            ["[èéêëēĕėęěȅȇȩɇ]", "e"],
            ["[ƒ]", "f"],
            ["[ĝğġģǥǧǵɠ]", "g"],
            ["[ĥħȟɦ]", "h"],
            ["[ìíîïĩīĭįǐȉȋɨ]", "i"],
            ["[ĵǰɉʝ]", "j"],
            ["[ķƙǩ]", "k"],
            ["[ĺļľŀłƚȴɫɬɭ]", "l"],
            ["[ɱ]", "m"],
            ["[ñńņňƞǹȵɲɳ]", "n"],
            ["[òóôõöøōŏőơǒǫǭǿȍȏȫȭȯȱ]", "o"],
            ["[œ]", "oe"],
            ["[ƥ]", "p"],
            ["[ɋʠ]", "q"],
            ["[ŕŗřȑȓɍɼɽɾ]", "r"],
            ["[śŝşšșȿʂ]", "s"],
            ["[ţťŧƫƭțȶʈ]", "t"],
            ["[ùúûüũūŭůűųưǔǖǘǚǜȕȗ]", "u"],
            ["[ʋ]", "v"],
            ["[ŵ]", "w"],
            ["[ýÿŷƴȳɏ]", "y"],
            ["[źżžƶǅȥɀʐʑ]", "z"],
        ];
        for (var i=0; i<map.length; ++i) {
            s = s.replace(new RegExp(map[i][0], "gi"), ((j)=> {
                return (match) => {
                    if (match.toUpperCase() === match) {
                        return map[j][1].toUpperCase();
                    } else {
                        return map[j][1];
                    }
                }
            })(i));
        }
        return s;
    },
    accentsTidyLw:(s)=>{
        return Utils.accentsTidy(s).toLowerCase();
    },
    filter:(s)=>{
      return Utils.accentsTidyLw(s).replace(/[^a-zA-Z0-9]/g,'-');
    },
    basename:(str, sep)=>{
      return str.substr(str.lastIndexOf(sep) + 1);
    },
}
export default Utils;
