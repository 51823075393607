import React, {useContext, useState, useCallback, useMemo} from "react";
import "./Places.scss";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import PlaceAdd from 'components/Place/PlaceAdd';
import { confirm } from 'components/Confirm'
import Fab from '@mui/material/Fab';
import {
  NavLink,
} from "react-router-dom";
import Text from 'components/form/Text';
import Utils from 'Utils/Utils';
import Pagination from '@mui/material/Pagination';

const itemsPerPage=12;

function Places() {
  const { modele,del } = useContext(ModelContext);
  const { appState,setAppState } = useContext(SettingsContext);
  const [ add, setAdd ] = useState(false);
  const places=useMemo(()=>modele.places || [],[modele]);
  const { placesFilter, placesPage } = appState;
  const setPlacesFilter=useCallback((placesFilter)=>{
    setAppState(state=>{return{...state,placesFilter,placesPage:1}});
  },[setAppState]);
  const setPlacesPage=useCallback((e,placesPage)=>{
    setAppState(state=>{return{...state,placesPage}});
  },[setAppState]);
  const handleAddPlace=()=>{
    setAdd(true);
  }
  const tile=useCallback((place)=>{
    const handleDeletePlace=(place)=>{
      confirm({
        titre: 'Supprimer le lieu ?',
        confirmation: <>Êtes vous sûr de vouloir supprimer <strong>{place.Nom}</strong>&nbsp;?</>,
      }).then((ok)=>{
        if (ok) del('places',place.id);
        else console.log('canceled');
      });
    };
    const name=place.forename ? place.forename+' '+place.name : place.name;
    return <Grid key={place.id} item xs={6} md={4} lg={3}>
      <Card>
        <CardContent className="sort-handle place-content">
          <Typography variant="body">
            {name || 'Sans nom'}
          </Typography>
        </CardContent>
        <CardActions>
          <NavLink to={'/place/'+place.id}><Button size="small">Modifier</Button></NavLink>
          <Button size="small" onClick={()=>handleDeletePlace(place)}>Supprimer</Button>
        </CardActions>
      </Card>
    </Grid>;
  },[del]);
  const filteredPlaces=useMemo(()=>{
    let tab = (placesFilter ? placesFilter.split(' ') : []).filter((o)=>o!=='').map(word=>Utils.accentsTidyLw(word));
    return places.filter((o)=>{
      let test = true;
      for (const word of tab) {
        test = test && (
          Utils.accentsTidyLw(o.name||'').indexOf(word) !==-1
          || Utils.accentsTidyLw(o.forename||'').indexOf(word) !==-1
        );
      };
      return test;
    });
  },[places,placesFilter]);
  const nb=useMemo(()=>Math.ceil(filteredPlaces.length/itemsPerPage),[filteredPlaces]);
  return <>
    <Container maxWidth="lg" className="places">
      <div className="spacer"/>
      <Typography variant="h1" component="h1">
        Lieux
      </Typography>
      <div className='spacer'></div>
      <Text name='Rechercher' autoFocus value={placesFilter} handleChange={setPlacesFilter} clear/>
      <div className='spacer'></div>
      <Pagination size='small' count={nb} page={placesPage} onChange={setPlacesPage}/>
      <div className='spacer'></div>
      <Grid container spacing={2}>
        {filteredPlaces.filter((o,i)=>i>=(placesPage-1)*itemsPerPage && i<placesPage*itemsPerPage).map((place)=>tile(place))}
      </Grid>
      <div className='spacer'></div>
      <Pagination size='small' count={nb} page={placesPage} onChange={setPlacesPage}/>
    </Container>
    <div className="floating-action">
      <Container maxWidth="lg">
      <Fab color="primary" aria-label="add" onClick={handleAddPlace}>
        <AddIcon />
      </Fab>
      </Container>
    </div>
    <PlaceAdd open={add} handleClose={()=>setAdd(false)}/>
  </>
}

export default Places;
