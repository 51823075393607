const Conf={
  apiUrl:process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "https://api.memorial-compiegne.fr/",
  filesUrl:process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL+"files/" : "https://api.memorial-compiegne.fr/files/",
  renewInterval:5*60*1000,
  roles:[
    {label:'Admin',value:'admin'},
    {label:'Équipe',value:'user'},
  ],
  stopTypes:[
    {value:'arrest',label:'Arrestation'},
    {value:'simple',label:'Étape'},
    {value:'impris',label:'Emprisonnement'},
    {value:'kommando',label:'Camp / Kommando'},
  ],
  langs:['fr','en','de'],
}
export default Conf;
