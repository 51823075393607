import React, { useState, useContext, useMemo } from "react";
import "./Itinerary.scss";
import { ModelContext } from "providers/ModelProvider";
import StopAdd from './StopAdd';
import StopMod from './StopMod';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Sortable from 'components/Sortable';
import { MapContainer, TileLayer, CircleMarker, Polyline } from 'react-leaflet'
import Grid from '@mui/material/Grid';
import { confirm } from 'components/Confirm'
import Conf from 'Conf';

const initialPosition=[48, 8];
const initialZoom=5;

function MyMarker({lat,ln,type}) {
  return <CircleMarker center={[lat, ln]} radius={type==='simple' ? 2 : 5} fillOpacity={1}/>;
}

function Itinerary({name='',value,handleChange,profilId}) {
  const [ add, setAdd ] = useState(false);
  const [ mod, setMod ] = useState(null);
  const { modele,post,del } = useContext(ModelContext);
  const places=useMemo(()=>modele.places || [],[modele]);
  const stops=useMemo(()=>(modele.stops || []).filter(o=>o.profilId===profilId),[modele,profilId]);
  const handleSort=(list)=>{
    console.log(list);
    post('stops|stopsBulk',{stops:list.filter((o)=>{
      const item=stops.find((s)=>s.id===o.id);
      return item.index!==o.index;
    }).map(o=>{return{id:o.id,index:o.index}})});
  }
  const handleDelete=(stop)=>{
      confirm({
        titre: 'Supprimer l\'étape ?',
        confirmation: <>Êtes vous sûr de vouloir supprimer l'étape&nbsp;?</>,
      }).then((ok)=>{
        if (ok) del('stops',stop.id);
        else console.log('canceled');
      });
    };
  const itinerary=useMemo(()=>stops.map((stop)=>{
    const place=places.find(o=>o.id===stop.placeId);
    return {...stop,lat:place ? place.coordinates_lat : null,ln:place ? place.coordinates_ln : null};
  }).filter(o=>o.lat && o.ln),[stops,places]);
  return (
    <div className="input-itinerary">
      {name && <Typography variant="h5" component="h5" className='input-upload-name'>
        {name}
      </Typography>}
      <div className='spacer'></div>
      <Grid container spacing={2}>
        <Grid item xs={5} md={4} lg={3}>
          <IconButton aria-label="bold" onClick={()=>setAdd(true)}>
            <AddIcon />
          </IconButton>
          <List dense>
            <Sortable list={stops} onChange={handleSort} sortKey="index" render={(stop)=>{
              const place=places.find(o=>o.id===stop.placeId);
              const type=Conf.stopTypes.find(o=>o.value===stop.type);
              return <ListItem key={stop.id}>
                <ListItemIcon className="sort-handle">
                  <DragHandleIcon />
                </ListItemIcon>
                <ListItemText
                  primary={place ? place.name : ''}
                  secondary={type ? type.label : ''}
                />
                <ListItemIcon>
                  <IconButton size="small" onClick={()=>setMod(stop.id)}><EditIcon /></IconButton>
                  <IconButton size="small" onClick={()=>handleDelete(stop)}><DeleteIcon /></IconButton>
                </ListItemIcon>
              </ListItem>;
            }}/>
          </List>
        </Grid>
        <Grid item xs={7} md={8} lg={9}>
          <div className="itinerary-map">
            <MapContainer className="carte-map" center={initialPosition} zoom={initialZoom} scrollWheelZoom={true} zoomControl={false}>
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {itinerary.map((stop)=><MyMarker key={stop.id} lat={stop.lat} ln={stop.ln} type={stop.type}/>)}
              <Polyline positions={itinerary.map((o)=>[o.lat,o.ln])}/>
            </MapContainer>
          </div>
        </Grid>
      </Grid>
      <StopAdd open={add} handleClose={()=>setAdd(false)} profilId={profilId}/>
      <StopMod id={mod} handleClose={()=>setMod(null)}/>
    </div>
  )
}

export default Itinerary;
