import React, { useState, createContext, useEffect, useCallback } from "react";
import { produce } from 'immer';
import axios from 'axios';
import {
  useNavigate,
} from "react-router-dom";
import Conf from 'Conf';

export const AuthContext = createContext({});
const authKey='compiegne_auth';
const authString=localStorage.getItem(authKey)
const authCache=authString ? JSON.parse(authString) : {token:null,id:null,username:null,role:null,name:null};
let renewInterval=null;

const AuthProvider = ({children})=>{
  const [ auth,setAuth ] = useState(authCache);
  const [ checked,setChecked ] = useState(false);
  const navigate = useNavigate();
  const onLoggedIn=useCallback(({token,id,username,role,name})=>{
    setAuth(produce(auth,(draft)=>{
      draft.token=token;
      draft.id=id;
      draft.name=name;
      draft.username=username;
      draft.role=role;
    }));
    localStorage.setItem(authKey, JSON.stringify({token,id,username,role,name}));
  },[auth,setAuth]);
  const onLoggedOut=useCallback(()=>{
    setAuth(produce(auth,(draft)=>{
      draft.token=null;
      draft.username=null;
      draft.name=null;
      draft.role=null;
      draft.id=null;
    }));
    localStorage.removeItem(authKey);
  },[auth,setAuth]);
  const renew=useCallback(()=>{
    axios.post(Conf.apiUrl+'renew', {}, { headers: { Authorization: 'Bearer '+auth.token} })
    .then(function (response) {
      if (response.data.loggedIn) {
        onLoggedIn(response.data);
      } else {
        onLoggedOut();
        navigate('/login', { replace: true });
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  },[auth.token,onLoggedIn,onLoggedOut,navigate]);
  useEffect(()=>{
    if(auth.token && !checked) {
      renew();
      setChecked(true);
      if (renewInterval) clearInterval(renewInterval);
      renewInterval=setInterval(renew,Conf.renewInterval);
    }
    if (!auth.token && renewInterval) clearInterval(renewInterval);
  },[auth.token,renew,checked,setChecked])
  return (
        <AuthContext.Provider value={{auth,setAuth,onLoggedIn,onLoggedOut,renew}}>
            {children}
        </AuthContext.Provider>
    );
}
export default AuthProvider;
