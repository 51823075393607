import React, {useContext,useState,useEffect, useMemo} from "react";
import "./User.scss";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { ModelContext } from "providers/ModelProvider";
import Text from 'components/form/Text';
import PasswordChange from './PasswordChange';
import Conf from 'Conf';
import _ from 'lodash';
import Button from '@mui/material/Button';

function Me() {
  const { modele,post } = useContext(ModelContext);
  const user=useMemo(()=>(modele.me && modele.me[0]) || {name:''},[modele.me]);
  const [modUser, setModUser] = useState({name:''});
  const valide=!_.isEqual(modUser,user);
  const handleOk=()=>{
    if (user && user.id) post('me',modUser);
  }
  const handleChange=(k,v)=>{
    setModUser((state)=>{return{...state,[k]:v}});
  }
  useEffect(()=>{
    if (user) setModUser(user);
  },[user]);
  return user ? <>
    <Container maxWidth="lg">
      <div className="spacer"/>
      <Typography variant="h1">
        Mon compte
      </Typography>
      <Typography variant="subtitle1">
        Login : <strong>{user.username}</strong>
      </Typography>
      <Typography variant="subtitle1">
        Rôle : <strong>{(Conf.roles.find((o)=>o.value===user.role)||{}).label}</strong>
      </Typography>
      <div className="mini-spacer"></div>
      <Text name='Nom' value={modUser.name} handleChange={(v)=>handleChange('name',v)} handleSubmit={handleOk}/>
      <PasswordChange user={user} type={'me'}/>
      <div className="mini-spacer"></div>
      <Button disabled={!valide} onClick={handleOk}>Modifier</Button>
    </Container>
  </> : '';
}

export default Me;
