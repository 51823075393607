import React, { useContext,useCallback } from "react";
import './Langswitch.scss';
import { SettingsContext } from "providers/SettingsProvider";
import { ModelContext } from "providers/ModelProvider";
import RichText from 'components/form/RichText';
import Text from 'components/form/Text';

function Langswitch({collection,idObjet,type,name,tradKey,traductions,onChange}) {
  const { modele } = useContext(ModelContext);
  const { appState, setAppState } = useContext(SettingsContext);
  const { lang } = appState;
  const { langs } = modele;
  const trad=useCallback((id_lang,key)=>{
    const res=traductions.find(o=>o.id_lang===id_lang && o.key===key);
    if (res) return res.value;
    return '';
  },[traductions]);
  const handleChange=(v)=>{
    const idx=traductions.findIndex(o=>o.id_lang===lang && o.key===tradKey);
    if (idx===-1) {
      if (v && v!=="<p></p>") onChange([...traductions,{id_lang:lang,collection,id_objet:idObjet,key:tradKey,value:v}]);
    } else {
      if (v!==traductions[idx].value) onChange([...traductions.slice(0,idx),{...traductions[idx],value:v},...traductions.slice(idx+1)]);
    }
  }
  return <div className="langswitch">
      {type==="richText" && <RichText key={`${tradKey}-${lang}`} name={name} value={trad(lang,tradKey)} handleChange={handleChange}/>}
      {type==="text" && <Text key={`${tradKey}-${lang}`} name={name} value={trad(lang,tradKey)} handleChange={handleChange}/>}
      <div className="tabs">
        {langs.map((l)=><div className={'lang-btn'+(lang===l.id ? ' active' : '')} key={l.id} onClick={()=>setAppState(state=>{return{...state,lang:l.id}})}>{l.name_short}</div>)}
      </div>
  </div>;
}

export default Langswitch;
