import React, { useState, useContext, useCallback, useMemo } from "react";
import "./Itinerary.scss";
import Button from '@mui/material/Button';
import Select from 'components/form/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ModelContext } from "providers/ModelProvider";
import Conf from 'Conf';
import Text from 'components/form/Text';

const skel={placeId:null,type:'simple',beginning_date:null,end_date:null,register_number:''};
function StopAdd({open,handleClose,profilId}) {
  const { post, modele } = useContext(ModelContext);
  const places=useMemo(()=>modele.places || [],[modele]);
  const [ addStop, setAddStop] = useState({...skel});
  const validateStop=(v)=>{
    if (!(v>0)) return {test:false,message:'Veuillez selectionner un lieu'};
    return {test:true};
  }
  const valide=validateStop(addStop.placeId).test;
  const handleOk=()=>{
    if (valide && addStop) post('stops',{...addStop,profilId:parseInt(profilId)},(res)=>{
      handleClose();
      setAddStop({...skel});
    });
  }
  const handleChange=useCallback((k)=>
  (v)=>{
    setAddStop((state)=>{return{...state,[k]:v}});
  },[setAddStop]);
  const handlers=useMemo(()=>{
    const res={};
    for (const field of Object.keys(skel)) {
      res[field]=handleChange(field);
    }
    return res;
  },[handleChange]);
  return <Dialog open={Boolean(open)} onClose={handleClose} fullWidth maxWidth='xs'>
      {Boolean(open) && <>
        <DialogTitle>Nouvelle étape</DialogTitle>
        <DialogContent>
          <div className="mini-spacer"></div>
          <Select name='Lieu' value={addStop.placeId || ''} handleChange={handlers['placeId']} options={places.map(o=>{return{value:o.id,label:o.name}})}/>
          <Select name='Type' value={addStop.type || ''} handleChange={handlers['type']} options={Conf.stopTypes}/>
          {addStop.type==='arrest' && <>
            <Text name="Date d'arrestation" value={addStop.beginning_date} handleChange={handlers['beginning_date']} handleSubmit={handleOk}/>
          </>}
          {['impris','kommando'].indexOf(addStop.type)!==-1 && <>
            <Text name='Date de début' value={addStop.beginning_date} handleChange={handlers['beginning_date']} handleSubmit={handleOk}/>
            <Text name='Date de fin' value={addStop.end_date} handleChange={handlers['end_date']} handleSubmit={handleOk}/>
            <Text name='Numéro de matricule' value={addStop.register_number || ''} handleChange={handlers['register_number']} handleSubmit={handleOk}/>
          </>}
          <div className="mini-spacer"></div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">Annuler</Button>
          <Button disabled={!valide} onClick={handleOk} variant="contained">Ajouter</Button>
        </DialogActions>
      </>}
    </Dialog>;
}

export default StopAdd;
