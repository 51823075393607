import React, { useState, useContext, useEffect, useMemo, useRef, useCallback } from "react";
import "./Place.scss";
import Button from '@mui/material/Button';
import Text from 'components/form/Text';
import Upload from 'components/form/Upload';
import { ModelContext } from "providers/ModelProvider";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import {
  useParams,
} from "react-router-dom";
import { createPatch, applyPatch } from 'rfc6902';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet'
import Leaflet from 'leaflet';
import Langswitch from 'components/Langswitch';

const initialPosition=[48, 8];
const initialZoom=5;

const LeafIcon = Leaflet.Icon.Default;
const icon= new LeafIcon({});

function MyMarker({lat,ln}) {
  return <Marker icon={icon} position={[lat, ln]}></Marker>;
}

function MyMap({setMap,events}) {
  useMapEvents(events);
  return null;
}


function Place() {
  const lastPlace=useRef({});
  const lastTraductions=useRef([]);
  const { id }=useParams();
  const { post, modele } = useContext(ModelContext);
  const place=useMemo(()=>(modele.places && modele.places.find(o=>o.id===parseInt(id))) || {},[modele,id]);
  const traductions=useMemo(()=>(modele.traductions && modele.traductions.filter(o=>o.collection==="places" && o.id_objet===parseInt(id))) || [],[modele,id]);
  const [modTraductions, setModTraductions] = useState([]);
  const [modPlace, setModPlace] = useState({});
  const [fields, setFields] = useState([]);
  useEffect(()=>{
    const currentFields=Object.keys(place);
    if(!_.isEqual(currentFields,fields)) {
      setFields(currentFields);
    }
  },[place,fields,setFields]);
  const validateNom=(v)=>{
    if (!v || v.length===0) return {test:false,message:'Veuillez saisir un nom'};
    return {test:true};
  }
  const valide=validateNom(modPlace.name).test;
  const handleOk=()=>{
    if (!saved && valide && place && place.id) post('places',modPlace,()=>{});
    if (!savedTraductions && traductions && place.id) {
      for(const tr of modTraductions) {
        if (!tr.id) post('traductions',tr,()=>{});
        else {
          const trOrig=traductions.find(o=>o.id===tr.id);
          if (tr.value!==trOrig.value) post('traductions',tr,()=>{});
        }
      }
    }
  }
  const handleChange=useCallback((k)=>
  (v)=>{
    setModPlace((state)=>{return{...state,[k]:v}});
  },[setModPlace]);
  const handlers=useMemo(()=>{
    const res={};
    for (const field of fields) {
      res[field]=handleChange(field);
    }
    return res;
  },[handleChange,fields]);
  const events=useMemo(()=>{
    return {
      click: (e) => {
        const {lat,lng} = e.latlng;
        handlers['coordinates_lat'](lat);
        handlers['coordinates_ln'](lng);
      },
    }
  },[handlers]);
  useEffect(()=>{
    if(!_.isEqual(place,lastPlace.current)) {
      const newPlace=_.cloneDeep(modPlace);
      const patch=createPatch(lastPlace.current,place);
      applyPatch(newPlace,patch);
      lastPlace.current=_.cloneDeep(place);
      setModPlace(newPlace);
    }
  },[place,setModPlace,modPlace]);
  useEffect(()=>{
    if(!_.isEqual(traductions,lastTraductions.current)) {
      const newTraductions=_.cloneDeep(modTraductions);
      const patch=createPatch(lastTraductions.current,traductions);
      applyPatch(newTraductions,patch);
      lastTraductions.current=_.cloneDeep(traductions);
      setModTraductions(newTraductions.filter((o,i,tab)=>{
        if(o.id) return true;
        for(const item of tab) {
          if(
            item.id
            && o.id_lang===item.id_lang
            && o.collection===item.collection
            && o.id_objet===item.id_objet
            && o.key===item.key
          ) return false;
        }
        return true;
      }));
    }
  },[traductions,setModTraductions,modTraductions]);
  const name=place.forename ? place.forename+' '+place.name : place.name;
  const saved=useMemo(()=>_.isEqual(place,modPlace),[place,modPlace]);
  const savedTraductions=useMemo(()=>{
    let test=true;
    for (const tr of modTraductions) {
      const orig=traductions.find(o=>o.id_lang===tr.id_lang && o.key===tr.key);
      if (!orig || orig.value!==tr.value) test=false;
    }
    return test;
  },[traductions,modTraductions]);
  return modPlace.name ? <Container maxWidth="lg" className="places">
    <div className="spacer"/>
    <Typography variant="h1" component="h1">
      Lieu : {name}
    </Typography>
    <div className="mini-spacer"></div>
    <Button disabled={!valide || (saved && savedTraductions)} onClick={handleOk} variant="contained">Enregistrer</Button>
    <div className='spacer'></div>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Text name='Nom' autoFocus value={modPlace.name || ''} handleChange={handlers['name']} handleSubmit={handleOk} validate={validateNom}/>
      </Grid>
      <Grid item xs={12}>
        <MapContainer className="carte-map" center={initialPosition} zoom={initialZoom} scrollWheelZoom={true} zoomControl={false}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {modPlace.coordinates_lat && modPlace.coordinates_ln && <MyMarker lat={modPlace.coordinates_lat} ln={modPlace.coordinates_ln}/>}
          <MyMap events={events}/>
        </MapContainer>
      </Grid>
      <Grid item xs={6}>
      <Langswitch
      idObjet={place.id}
      tradKey="description"
      collection="places"
      type="richText"
      name="Descriptif"
      traductions={modTraductions}
      onChange={setModTraductions}
      />
      </Grid>
      <Grid item xs={6}>
        <Upload name='Image' value={modPlace.picture || []} path="places/picture" docId={id} type="image" handleChange={handlers['picture']}/>
      </Grid>
      </Grid>
    <div className="mini-spacer"></div>
    <Button disabled={!valide || (saved && savedTraductions)} onClick={handleOk} variant="contained">Enregistrer</Button>
  </Container> : '';
}

export default Place;
