import React, {useState, useRef, useEffect, useMemo} from "react";
import "./RichText.scss";
import {Editor, EditorState, RichUtils} from 'draft-js';
import 'draft-js/dist/Draft.css';
import IconButton from '@mui/material/IconButton';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {convertToHTML,convertFromHTML} from 'draft-convert';
import _ from 'lodash';
import {decode} from 'html-entities';

function RichText({name='',inputRef=null,endAdornment=undefined,autoFocus=false,value,handleChange,validate=()=>{return {test:true}},type='text',handleBlur=()=>{},disabled=undefined}) {
  const editor=useRef();
  const cache=useRef(null);
  const [editorState, setEditorState] = useState(
    () => EditorState.createWithContent(convertFromHTML(value)),
  );
  const toggleStyle=(e,style)=>{
    e.preventDefault();
    setEditorState(state=>RichUtils.toggleInlineStyle(state, style));
  }
  const handleKeyCommand=(command, editorState)=>{
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  }
  const debounced=useMemo(()=>_.debounce(handleChange,500,{leading:true}),[handleChange]);
  useEffect(()=>{
    const html = decode(convertToHTML(editorState.getCurrentContent()));
    if (html!==cache.current) {
      cache.current=html;
      debounced(html);
    }
  },[editorState,debounced]);
  useEffect(()=>{
    if (value!==cache.current) {
      cache.current=value;
      console.log('change state');
      setEditorState(EditorState.createWithContent(convertFromHTML(value)));
    }
  },[value]);
  return (
    <div className={"input-rich"+(validate(value).test ? '' : ' invalid')} onClick={()=>editor.current.focus()}>
      {name && <Typography variant="h5" component="h5" className='input-upload-name'>
        {name}
      </Typography>}
      <div className='spacer'></div>
      <Paper>
          <div className="richeditor-ctls">
            <IconButton aria-label="bold" onMouseDown={(e)=>toggleStyle(e,'BOLD')} size='small'>
              <FormatBoldIcon />
            </IconButton>
            <IconButton aria-label="bold" onMouseDown={(e)=>toggleStyle(e,'ITALIC')} size='small'>
              <FormatItalicIcon />
            </IconButton>
            <IconButton aria-label="bold" onMouseDown={(e)=>toggleStyle(e,'UNDERLINE')} size='small'>
              <FormatUnderlinedIcon />
            </IconButton>
          </div>
          <div className="richeditor">
            <Editor ref={editor} editorState={editorState} onChange={setEditorState} handleKeyCommand={handleKeyCommand}/>
          </div>
        </Paper>
      {validate(value).message && <div className="input-text-message">{validate(value).message}</div>}
    </div>
  )
}

export default RichText;
