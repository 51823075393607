import React, {useState,useEffect,useMemo,useCallback} from "react";
import "./Sortable.scss";
import { ReactSortable } from "react-sortablejs";
import memoize from "memoizee";

function Sortable({list,onChange,render,wrapper=null,sortKey='Sort'}) {
  const [state, setState] = useState([]);
  useEffect(()=>{
    setState(list.map((o)=>{return{...o}}));
  },[list]);
  const setList = useCallback((newList) => {
    setState(newList.map((o)=>{return{...o}}));
    let changed=false;
    const res=[];
    newList.forEach((item, i) => {
      if (item[sortKey]!==i) {
        changed=true;
        res.push({...item,[sortKey]:i});
      } else {
        res.push(item);
      }
    });
    if (changed) {
      onChange(res);
    }
  },[setState,onChange,sortKey]);
  const memoized = useMemo(()=>memoize(render,
  	{
  		normalizer: function(args) {
  			// args is arguments object as accessible in memoized function
  			return JSON.stringify(args[0]);
  		}
  	}
  ),[render]);
  useEffect(()=>{
    setList(list);
  },[list,setList]);
  return <ReactSortable tag={wrapper} list={state} setList={setList} handle='.sort-handle'>
    {state.map((item) => memoized(item))}
  </ReactSortable>;
}

export default Sortable;
